import { defineStore } from 'pinia'
import type { MessageThreadDtoRes, MessageDto, ProjectDtoRes } from '@/types/api';
import * as storage from '~/stores/localStorage'
const key = 'useThreadsStore'
import moment from 'moment'

export const useThreadsStore = defineStore(key, () => {


  const _data = ref<any>(null)
  const _loading = ref<boolean>(false)
  const dateFetched = ref<Date>(null)
  const dataFetchStarted = ref<Date>(null)
  
  const data = computed<Array<MessageThreadDtoRes>>(() => _data.value)
  const ready = computed<boolean>(() => _data.value ? true : false)
  const loading = computed<boolean>(() => _loading.value)
  const timestamp = computed<string>(() => dateFetched.value.toISOString())
  
  const projects = useProjectStore()
  
  const init = () => {
    const data = load(key)
  
    if(data){
      _data.value = data.payload 
      dateFetched.value = new Date(data.timestamp)
      get()
      return true
    }
      
    return get() 
  }
  
  
  const get = async(id?: number) => { 
    
    try{
      _loading.value = true
      dataFetchStarted.value = new Date()
      
      if(typeof id === 'number' ){
        
        const { data, error } = await useApi('/message-thread/{id}', { path: { id } })
        if(error.value){
          _loading.value = false
          throw error
        }
        
        const index = _data.value.findIndex(el => el.id == id)
        
        if(index != -1){
          _data.value[index] = processThreadType(data.value)
          _data.value[index].latestActivityDate = new Date().toISOString()
        }
          
        
        else 
          _data.value.push(processThreadType(data.value))
        
        _data.value = _data.value.sort(sortByDate)
      }
      
      else{
        const { data, error } = await useFapi('/v1/message-threads/')//useApi('/message-thread/logged/user')
        
        if(error.value){
          _loading.value = false
          throw error
        }
        
        if(dateFetched.value > dataFetchStarted.value){
          _loading.value = false
          return false 
        }
        
        // @ts-ignore
        _data.value = data.value.map(el => processThreadType(el)).reverse().sort(sortByDate)
      }

      dateFetched.value = new Date()
      storage.set(key, _data.value)
      _loading.value = false
      return true
      
    }
    catch(err){
      throw err
    }
  }
  
  const addMessage = (message: MessageDto) => {
    const threadIndex = _data.value.findIndex(el => el.id == message.messageThreadId)
    if(threadIndex == -1)
      return false 
    
    _data.value[threadIndex].messages = [message, ..._data.value[threadIndex].messages]
    _data.value[threadIndex].latestActivityDate = new Date().toISOString()
    dateFetched.value = new Date()
    
    _data.value = _data.value.sort(sortByDate)
  }
  
  const setMessage = (message: MessageDto) => {
    const threadIndex = _data.value.findIndex(el => el.id == message.messageThreadId)
    if(threadIndex == -1)
      return false 
    
    const messageIndex = _data.value[threadIndex].messages.findIndex(el => el.id == message.id)
    
    if(messageIndex == -1)
      return false 
    
    _data.value[threadIndex].messages[messageIndex] = message
    
    dateFetched.value = new Date()
    return _data.value[threadIndex].messages[messageIndex]
    
    
  }
  
  const processThreadType = (thread: MessageThreadDtoRes) => {
    
    
    
    if(thread.messageType === 'MESSAGE'){
      return{
        ...thread,
        messages: thread.messages.map(el => {return {...el, type: 'MESSAGE'}})
      }
    }

    
    const messageTypes = {
      AGREEMENT_THREAD: 'agreementThreads',
      CHANGE_NOTIFICATION: 'changeNotifications',
      OFFER: 'offers',
      MESSAGE: 'messages',
    }
    
  
    //console.log('!thread[messageTypes[thread.messageType]].length  => ', !thread[messageTypes[thread.messageType]].length  )
    if(
      typeof messageTypes[thread.messageType] === 'undefined' 
      || !thread[messageTypes[thread.messageType]] 
      || !Array.isArray(thread[messageTypes[thread.messageType]]) 
      || !thread[messageTypes[thread.messageType]].length
    ){
      return thread.messages.map(el => {return {...el, type: 'MESSAGE'}})
    }
      
    
    thread.messages = [
      ...thread.messages.map(el => {return {...el, type: 'MESSAGE'}}),
      ...thread[messageTypes[thread.messageType]].map(el => {return {...el, type: thread.messageType}})
    ]
    
    
    return thread
  }
  
  const sortByDate = (a, b) =>{
    return moment(a.latestActivityDate).isAfter(b.latestActivityDate) ? -1 : 1
  }
  
 

  return { data, get, ready, setMessage, addMessage, init, loading, timestamp }
  
})